// 智能中药盒 详情页
import { Fragment } from 'react';
import Buy from '../Buy';

import './index.less';


const Intelligence = () => {
    return (
        <Fragment>
            <Buy imgSource={require('@/image/product_intelligence.png')} shopLink='https://www.taobao.com' />
            <img src={require('@/image/detail_intelligence1.png')} alt='智能药盒详情1' />
            <img src={require('@/image/detail_intelligence2.png')} alt='智能药盒详情2' />
            <img src={require('@/image/detail_intelligence3.png')} alt='智能药盒详情3' />
            <div className='video_container'><video className='video_source' poster='' controls loop autoPlay muted>
                    <source src={require('../../image/123video.mp4')} type='video/mp4'></source>
                </video>
                <img src={require('@/image/detail_intelligence4.png')} alt='智能药盒详情4' />
            </div>
        </Fragment>
    );
}

export default Intelligence;