// 首页
import { Fragment } from 'react';
// 组件
import Banner from '@/component/Banner';

// import './index.less';

const Home = () => {

    return (
        <Fragment>
            {/* 首页公司图 */}
            <img src={require('@/image/home.png')} alt='首页' className='publicImg' />
            {/* 轮播图 */}
            <Banner />
            {/* 关于我们 */}
            <img src={require('@/image/about.png')} alt='关于我们' className='publicImg' />
        </Fragment>
    );
}

export default Home;