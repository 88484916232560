import React, { Component } from 'react'
// 导入所需组件
import Nav from '@/component/Nav';
import Home from '@/component/Home';
import Product from '@/component/Product';
import Intelligence from '@/component/Intelligence';
import RenChangJiu from '@/component/RenChangJiu';
import Xinzhu from '@/component/Xinzhu';
import About from '@/component/About';
// 导入路由依赖
import { Routes, Route } from 'react-router-dom'

export default class index extends Component {
    render() {
        return (
            <div>
                {/* 导航栏 */}
                <Nav />
                <Routes>
                    {/* 使用/配置路由默认页；exact严格匹配 */}
                    <Route element={<Home />} path='/' exact></Route>
                    <Route element={<Product />} path='/product'></Route>
                    <Route element={<About />} path='/about'></Route>
                    <Route element={<Intelligence />} path='/product/intelligence'></Route>
                    <Route element={<RenChangJiu />} path='/product/renchangjiu'></Route>
                    <Route element={<Xinzhu />} path='/product/xinzhu'></Route>
                </Routes>
            </div>
        )
    }
}