// 新竹 详情页
import { Fragment } from 'react';
import Buy from '../Buy';

// import './index.less';


const Xinzhu = () => {
    return (
        <Fragment>
            <Buy className='buy_xinzhu' imgSource={require('@/image/product_xinzhu.png')} shopLink='https://www.taobao.com' />
            <img src={require('@/image/detail_xinzhu1.png')} alt='新竹详情图1' />
            <img src={require('@/image/detail_xinzhu2.png')} alt='新竹详情图2' />
            <img src={require('@/image/detail_xinzhu3.png')} alt='新竹详情图3' />
        </Fragment>
    );
}

export default Xinzhu;
