// 导航栏
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import './index.less';

function Nav() {
    return (
        <div className='header_container'>
            <NavLink to='/' className={({ isActive }) => classNames('nav', { activeStyle: isActive })}>
                <p className='nav_inner'>首页</p>
            </NavLink>
            {/* <div className='nav_divide'>|</div> */}
            <div className='nav_container'>
                <NavLink to='/product' className={({ isActive }) => classNames('nav', { activeStyle: isActive })}>
                <p className='nav_inner'>产品中心</p>
                </NavLink>
                <div className='sub_nav_container'>
                    <NavLink to='/product/intelligence' className='sub_nav'>
                        <p>智能中药盒</p>
                    </NavLink>
                    {/* 二级导航 */}
                    <div className='sub_nav'>
                        <div className='sub_nav_inner'>
                            <p>准时药盒</p>
                            <i className='iconfont icon-jiantouyou nav_arrow'></i>
                        </div>
                        {/* 三级导航 */}
                        <div className='sub_sub_nav'>
                            <NavLink className='sub_sub_nav_inner' to='/product/renchangjiu'>但愿人长久</NavLink>
                            <NavLink className='sub_sub_nav_inner' to='/product/xinzhu'>新竹系列</NavLink>
                        </div>
                    </div>
                    <div className='sub_nav'>
                        <p>盐盒</p>
                    </div>
                    <div className='sub_nav'>
                        <p>敬请期待...</p>
                    </div>
                </div>
            </div>
            {/* <div className='nav_divide'>|</div> */}
            <NavLink to='/about' className={({ isActive }) => classNames('nav', { activeStyle: isActive })}>
                <p className='nav_inner'>关于我们</p>
            </NavLink>
        </div>
    );
}

export default Nav;