// 但愿人长久 详情页
import { Fragment } from 'react';
import Buy from '../Buy';

// import './index.less';


const RenChangJiu = () => {
    return (
        <Fragment>
            <Buy className='buy_renchangjiu' imgSource={require('@/image/product_renchangjiu.png')} shopLink='https://www.taobao.com' />
            <img src={require('@/image/detail_renchangjiu1.png')} alt='但愿人长久详情1' />
            <img src={require('@/image/detail_renchangjiu2.png')} alt='但愿人长久详情2' />
            <img src={require('@/image/detail_renchangjiu3.png')} alt='但愿人长久详情3' />
        </Fragment>
    );
}

export default RenChangJiu;