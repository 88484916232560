// 关于我们
import { Fragment } from 'react';
import './index.css';

const Home = () => {

    return (
        <Fragment>
            {/* 关于我们 */}
            <img src={require('@/image/about.png')} alt='关于我们' className='publicImg' />
        </Fragment>
    );
}

export default Home;