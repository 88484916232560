// 轮播产品信息
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper';
import Buy from '../Buy';

import './index.less';

const Banner = () => {

    return (
        <Swiper
            modules={[Autoplay, Navigation, Pagination]}
            spaceBetween={50}
            loop={true}
            autoplay={{ delay: 3000 }}
            navigation
            pagination={{ clickable: true }}
            disableoninteraction="false"
        >
            {/* 智能中药盒 */}
            <SwiperSlide className='swiperContent'>
                <Buy imgSource={require('@/image/product_intelligence.png')} shopLink='http://www.x6tb.com/group.html' detailLink='/product/intelligence' />
            </SwiperSlide>
            {/* 准时药盒_但愿人长久 */}
            <SwiperSlide className='swiperContent'>
                <Buy className='buy_renchangjiu' imgSource={require('@/image/product_renchangjiu.png')} shopLink='http://www.x6tb.com/group.html' detailLink='/product/renchangjiu' />
            </SwiperSlide>
            {/* 准时药盒_新竹 */}
            <SwiperSlide className='swiperContent'>
                <Buy className='buy_xinzhu' imgSource={require('@/image/product_xinzhu.png')} shopLink='http://www.x6tb.com/group.html' detailLink='/product/xinzhu' />
            </SwiperSlide>
        </Swiper>
    );
}

export default Banner;