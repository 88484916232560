// 产品中心
import { Fragment } from 'react';
import Buy from '../Buy';

import './index.less';


const Product = () => {
    return (
        <Fragment>
            {/* 智能中药盒 */}
            <Buy imgSource={require('@/image/product_intelligence.png')} shopLink='https://www.taobao.com' detailLink='/product/intelligence' />
            {/* 准时药盒_但愿人长久 */}
            <Buy className='buy_renchangjiu' imgSource={require('@/image/product_renchangjiu.png')} shopLink='https://www.taobao.com' detailLink='/product/renchangjiu' />
            {/* 准时药盒_新竹 */}
            <Buy className='buy_xinzhu' imgSource={require('@/image/product_xinzhu.png')} shopLink='https://www.taobao.com' detailLink='/product/xinzhu' />
        </Fragment>
    );
}

export default Product;