// 立即购买
import classNames from 'classnames';
import './index.less';


const Buy = (props = {}) => {
    const { imgSource, detailLink, shopLink, className = '' } = props;

    // 跳转详情
    const jumpDetail = () => {
        if (!detailLink) return;
        window.location.href = detailLink;
    };

    // 跳转购买
    const jumpShopping = (e) => {
        e?.stopPropagation();
        window.open(shopLink);
    };

    return (
        <div className={classNames('buy_container', className)} onClick={jumpDetail}>
            {/* 主图 */}
            <img src={imgSource} alt='' />
            {/* 购买入口 */}
            <img className='buy_btn' src={require('@/image/buy.png')} alt='立即购买' onClick={jumpShopping} />
            {/* 详情页入口 */}
            {
                detailLink &&
                <img
                    className='buy_more'
                    src={require('@/image/more.png')}
                    alt='了解更多'
                />
            }

        </div>
    );
}

export default Buy;